import React, { useState, useContext, useEffect, useMemo } from 'react'
import ToastContext from '../context/ToastProvider';
import { LocalContext } from '../context/LocalContext';

import ProductEditContext from '../context/ProductEditProvider';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseUrl, PRODUCTS, CATEGORIES } from '../api/Api'
import Cookie from 'cookie-universal';

import { Typography, Stack, Box, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { red, green, orange } from '@mui/material/colors';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import '../css/producttable.css'

export default function ProductsTable() {
  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState([]);
  const { updatedRows, setUpdatedRows, setRowToEdit, selectedImages, setSelectedImages, imagePreviews, setImagePreviews } = useContext(ProductEditContext);
  const { locale, setLocale } = useContext(LocalContext);
  const { showHideToast } = useContext(ToastContext);
  const [t] = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  //  ====== get all data ========
  useEffect(() => {
    handleOpen();
    axios.get(`${baseUrl}${PRODUCTS}`,)
      .then(function (response) {
        console.log('products ', response.data.products);
        setProduct(response.data.products);
        handleClose();
      })
      .catch(function (error) {
        console.log(error);
        showHideToast(error.response.data.message, 'error');
        handleClose();
      }
      );
  }, []);

  useEffect(() => {
    handleOpen();
    axios.get(`${baseUrl}${CATEGORIES}`,)
      .then(function (response) {
        // console.log('categories', response.data.categories);
        setCategory(response.data.categories);
        handleClose();
      })
      .catch(function (error) {
        console.log(error);
        showHideToast(error.response.data.message, 'error');
        handleClose();
      }
      );
  }, []);
  //  ====== get all data ========

  //  ====== open && close view state ========
  const [showViewDialog, setShowViewDialog] = useState(false);
  const [rowToView, setRowToView] = useState(null);
  //  ====== open && close view state ========

  //  ================ add function ================
  function handleAddClick() {
    const imagePaths = [];
    setImagePreviews(imagePaths);
    navigate('/products/new')
  }
  //  ================ add function ================

  //  ================ edit function ================
  function handleEditClick(id) {
    const row = product.find((row) => row.id === id);
    setUpdatedRows({
      NameEN: row.Name_en,
      NameAR: row.Name_ar,
      Status: row.status,
      Price: row.price,
      SalePrice: row.sale_price,
      Quantity: row.stock,
      DescriptionEn: row.Description_en,
      DescriptionAr: row.Description_ar,
      Category: row.category_id,
    });
    const imagePaths = row.images ? row.images.split(',') : [];
    setImagePreviews(imagePaths);

    // Populate selectedImages with File objects
    const fileObjects = imagePaths.map(path => ({ path, isNew: false }));
    setSelectedImages(fileObjects);

    setRowToEdit(id);
    navigate(`/products/${id}/edit`);
  }
  //  ================ edit function ================

  //  ====== open && close delete state ========
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  //  ====== open && close delete state ========

  //  ================ delete function ================
  function handleDeleteClick(id) {
    setRowToDelete(id);
    setShowDeleteDialog(true);
  }

  function handleDeleteClose() {
    setShowDeleteDialog(false);
  }

  async function handleDeleteConfirm() {
    handleOpen();
    const cookies = Cookie()
    const token = cookies.get('e-commerce')

    try {
      const response = await axios.delete(`${baseUrl}${PRODUCTS}/${rowToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const updatedRow = product.filter((row) => row.id !== rowToDelete);
      // console.log(updatedRow);
      setProduct(updatedRow);
      handleDeleteClose();
      showHideToast(t('Deleted successfully'));
      handleClose();
    } catch (error) {
      console.log(error);
      handleClose();
    }
  }
  //  ================ delete function ================

  //  ================ view function ================
  function handleViewClick(row) {
    setShowViewDialog(true);
    setRowToView(row);
  }
  // console.log(rowToView);
  //  ================ view function ================

  const columns = useMemo(
    () => [
      {
        accessorKey: 'index',
        header: '#',
        size: 80,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'images',
        header: 'Image',
        size: 100,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
        Cell: ({ cell }) => {
          const images = cell.getValue().split(',');
          return (
            <img src={images[0]} alt="Product" style={{ maxWidth: '50%', maxHeight: '50px' }} />
          );
        },
      },
      {
        accessorKey: 'Name_en',
        header: (t('Name EN')),
        size: 100,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
          style: { whiteSpace: 'wrap' },
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'Name_ar',
        header: (t('Name AR')),
        size: 100,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
          style: { whiteSpace: 'wrap' },
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'status',
        header: (t('Status')),
        size: 100,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
        Cell: ({ cell }) => (
          <Box
            component="div"
            sx={{
              backgroundColor:
                cell.getValue() === 'published'
                  ? green[100]
                  : cell.getValue() === 'draft'
                    ? orange[100]
                    : red[100],
              border: `1px solid ${cell.getValue() === 'published'
                ? green[300]
                : cell.getValue() === 'draft'
                  ? orange[300]
                  : red[300]}`,
              borderRadius: '4px',
              color:
                cell.getValue() === 'published'
                  ? green[700]
                  : cell.getValue() === 'draft'
                    ? orange[700]
                    : red[700],
            }}
          >
            {cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: 'category_id',
        header: (t('Category')),
        size: 100,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
        Cell: ({ cell }) => {
          const categoryId = cell.row.original.category_id;
          const categoryApi = category.find(cat => cat.id === categoryId);
          return categoryApi ? (locale === 'en' ? categoryApi.Name_en : categoryApi.Name_ar) : '';
        }
      },
      {
        accessorKey: 'price',
        header: (t('Price')),
        size: 100,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'sale_price',
        header: (t('SalePrice')),
        size: 100,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'stock',
        header: (t('Quantity')),
        size: 100,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'Action',
        header: (t('Actions')),
        size: 150,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
        Cell: ({ cell }) => (
          <Stack direction="row" spacing={0.5} sx={{ justifyContent: 'center' }}>
            <IconButton onClick={() => handleViewClick(cell.row.original)}>
              <VisibilityOutlinedIcon color="grey" fontSize="small" />
            </IconButton>
            <IconButton onClick={() => handleEditClick(cell.row.original.id)}>
              <ModeEditIcon color="primary" fontSize="small" />
            </IconButton>
            <IconButton onClick={() => handleDeleteClick(cell.row.original.id)}>
              <DeleteIcon color="error" fontSize="small" />
            </IconButton>
          </Stack>
        ),
      },
    ],
    [t, handleDeleteClick, handleEditClick, product, setProduct],
  );

  const renderedRows = useMemo(() => {
    return product.map((row, index) => {
      return { ...row, index: index + 1 };
    });
  }, [product]);

  const table = useMaterialReactTable({
    columns,
    data: renderedRows,
    initialState: { density: 'compact' },
  });

  return (
    <div dir={locale === "en" ? "ltr" : "rtl"} className={locale === "en" ? "ltr" : "rtl"} style={{ marginTop: '20px' }}>
      {open && <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>}
      <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography sx={{ fontSize: '20px', fontWeight: 'bold', mb: '20px' }}>
          {t('Products')}
        </Typography>
        <Stack onClick={handleAddClick} direction='row' className='addBtn' >
          <div className='addBtn-link' >
            <span style={{ display: 'inherit' }}>
              <AddIcon sx={{ fontSize: "20px" }} />
            </span>
            New Product
          </div>
        </Stack>
      </Stack>



      <MaterialReactTable table={table} />

      {/* ================ delete dialog ================ */}
      <Dialog
        dir={locale === "en" ? "ltr" : "rtl"}
        // className={locale === "en" ? "ltr" : "rtl"}
        open={showDeleteDialog}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('Are you sure you want to delete this item?')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className='dialogDelete' sx={{ padding: "0 0 10px !important", }} id="alert-dialog-description">
            {t('This item will be permanently deleted.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleDeleteClose} sx={{ textTransform: 'capitalize' }}>{t('Disagree')}</Button>
          <Button className='dialogDeleteBtn' variant="contained" color="error" onClick={handleDeleteConfirm} sx={{ textTransform: 'capitalize' }} autoFocus>
            {t('Agree')}
          </Button>
        </DialogActions>
      </Dialog>
      {/* ================ delete dialog ================ */}

    </div>
  )
}