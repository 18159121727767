import { useRef, useEffect, useState, useContext } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { LOGIN, SETTINGS, baseUrl } from '../api/Api';

import  ToastContext  from '../context/ToastProvider';
import { LocalContext } from '../context/LocalContext';
import { useTranslation } from 'react-i18next';
import Cookie from 'cookie-universal'

import '../css/login.css';
import axios from 'axios';

export default function Login() {
    const [logo, setLogo] = useState('');
    const [logoLoading, setLogoLoading] = useState(true);
    const { locale, setLocale } = useContext(LocalContext);
    const { showHideToast } = useContext(ToastContext);
    const [t] = useTranslation("global");

    const cookies = Cookie()
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();


    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');

    // logo
    useEffect(() => {
        axios.get(`${baseUrl}${SETTINGS}`)
            .then(response => {
                // console.log(response.data);
                const logoUrl = response.data.map(item => item.value);
                setLogo(logoUrl);
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                     if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                showHideToast(errorMessage, "error");
            })
            .finally(() => {
                setLogoLoading(false); // Set logoLoading to false when logo is fetched or if there's an error
            });
    }, []);
    // logo

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${baseUrl}${LOGIN}`,
                { email: email, password: pwd },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            console.log(response);
            const accessToken = response?.data?.access_token;
            const roles = response?.data?.user?.is_admin;
            cookies.set('e-commerce', accessToken)
            showHideToast("login success");
            setEmail('');
            setPwd('');
            // navigate(from, { replace: true });
            window.location.pathname = '/';
        } catch (err) {
            let errorMessage = 'An error occurred. Please try again.';
            if (err.response) {
                if (err.response.status === 400) {
                    errorMessage = 'Enter valid email. Please check your input.';
                } else if (err.response.status === 401) {
                    errorMessage = 'Unauthorized. Please log in again.';
                } else if (err.response.status === 403) {
                    errorMessage = 'Forbidden. You do not have permission to perform this action.';
                } else if (err.response.status === 404) {
                    errorMessage = 'Resource not found.';
                } else if (err.response.status === 500) {
                    errorMessage = 'Internal server error. Please try again later.';
                } else {
                    errorMessage = 'An unexpected error occurred. Please try again later.';
                }
            }
            showHideToast(errorMessage, "error");
        }
    }

    return (
        <div className="login-main">
            <div className="login">
                <div>
                    {logoLoading ? (
                        <div></div> // Render loader while logo is loading
                    ) : (
                        <>
                            <img style={{ maxWidth: '100%', height: '68px', marginBottom: '20px' }} src={logo} alt="logo" />
                            {/* <h1>Sign In</h1> */}
                        </>
                    )}
                </div>
                <form onSubmit={handleSubmit}>
                    <TextField autoFocus size='small' sx={{ marginBottom: 1 }} required value={email} onChange={(e) => setEmail(e.target.value)} ref={userRef} id="username" autoComplete="off" label="Email" variant="outlined" />
                    <TextField size='small' sx={{ marginBottom: 1 }} required value={pwd} onChange={(e) => setPwd(e.target.value)} ref={userRef} id="password" label="Password" variant="outlined" type="password" />
                    <button>Sign In</button>
                </form>
                <div className="login-footer">Copyright © 2024 SitePocket</div>
            </div >
        </div>
    )
}
