import React, { useState, useContext, } from 'react'
import { LocalContext } from '../context/LocalContext';
import ToastContext from '../context/ToastProvider';
import  ProductEditContext  from '../context/ProductEditProvider';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Axios } from '../api/Axios';
import { USERS } from '../api/Api';

import '../css/usersadd.css';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import { Stack } from '@mui/material';


export default function UsersEdit() {
  const [users, setUsers] = useState([]);
  const { updatedUsers, setUpdatedUsers, rowToEdit } = useContext(ProductEditContext);
  const { locale, setLocale } = useContext(LocalContext);
  const { showHideToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const [t] = useTranslation();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  function handleForm(e) {
    setUpdatedUsers({ ...updatedUsers, [e.target.name]: e.target.value })
  }
  //  ================ edit function ================
  async function handleEditSubmit(e) {
    e.preventDefault();
    handleOpen();
    const params = {
      name: updatedUsers.Name,
      email: updatedUsers.Email,
      password: updatedUsers.Password,
      is_admin: updatedUsers.Admin
    }
    try {
      const response = await Axios.put(`${USERS}/${rowToEdit}`, params)
      // console.log(response);
      const newUser = response.data.user;
      const updatedRowsArray = users.map((row) => {
          if (row.id === rowToEdit) {
              return {
                  ...row,
                  Name: newUser.name,
                  Email: newUser.email,
                  Password: newUser.password,
                  Admin: newUser.is_admin
              };
          }
          return row;
      })
      setUsers(updatedRowsArray);
      showHideToast(t("Updated successfully"));
      handleClose();
      navigate("/users");
    } catch (error) {
      console.log(error);
      handleClose();
    }
  }
  //  ================ edit function ================

  return (
    <div dir={locale === "en" ? "ltr" : "rtl"} className={[locale === "en" ? "ltr" : "rtl", "usersadd"].join(" ")}>
      {open && <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>}
      <Container maxWidth="lg">
        <h4 style={{ fontSize: '24px', fontWeight: 'bold', margin: '0 0 40px 0' }}>
          {t('Edit')}
        </h4>

        <Grid container spacing={2}>
          <Grid xs={12} md={8}>
            <div className="firstbox">
              <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', padding: '24px' }}>
                <TextField type='text' autoFocus name='Name' value={updatedUsers.Name} onChange={handleForm} fullWidth id="Name" label={t("User Name")} variant="outlined" />
                <TextField type='email' name='Email' value={updatedUsers.Email} onChange={handleForm} fullWidth id="Email" label={t("User Email")} variant="outlined" />
                <TextField type='password' name='Password' value={updatedUsers.Password} onChange={handleForm} fullWidth id="Password" label={t("User Password")} variant="outlined" />
                <Stack direction="row" alignItems="center" spacing={1}>
                  <span>{t('Admin')}</span>
                  <Switch
                    checked={Boolean(updatedUsers.Admin)}
                    onChange={(e) => {
                      setUpdatedUsers({ ...updatedUsers, Admin: e.target.checked })
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </Stack>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid md={4}></Grid>
          <Grid xs={12} md={8} sx={{ padding: '16px', textAlign: 'right' }}>
          <button onClick={handleEditSubmit} className='submitbtn' variant="contained" type="submit">{t('Save Changes')}</button>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
