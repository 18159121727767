import axios from 'axios';
import Cookie from 'cookie-universal';
import { baseUrl } from '../api/Api';

const cookies = Cookie()
const token = cookies.get('e-commerce')

export const Axios = axios.create({
    baseURL: baseUrl,
    headers: {
        Authorization: `Bearer ${token}`
    }
})