import './App.css';
import { Routes, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import global_en from './translations/en/global.json';
import global_ar from './translations/ar/global.json';
import { ToastProvider } from './context/ToastProvider';

import { useState } from 'react';
import { LocalContext } from "./context/LocalContext";
import { DataProvider } from './context/DataProvider';
import { ProductEditProvider } from './context/ProductEditProvider';


import Dashboard from "./pages/Dashboard";
import Products from "./pages/Products";
import Orders from "./pages/Orders";
import Users from "./pages/Users";
import Categories from "./pages/Categories";
import ProductsAdd from "./fullpage/ProductsAdd";
import ProductsEdit from "./fullpage/ProductsEdit";
import CategoryAdd from "./fullpage/CategoryAdd";
import CategoryEdit from "./fullpage/CategoryEdit";
import OrdersAdd from "./fullpage/OrdersAdd";
import OrdersEdit from "./fullpage/OrdersEdit";
import OrdersView from './fullpage/OrdersView';
import Login from './pages/Login';
import UsersAdd from './fullpage/UsersAdd';
import UsersEdit from './fullpage/UsersEdit';
import Transaction from './pages/Transaction';
import TranscationAdd from './fullpage/TranscationAdd';
import TranscationEdit from './fullpage/TranscationEdit';
import RequireAuth from './auth/RequireAuth';
import Writer from './pages/Writer';
import Page404 from './pages/Page404';
import Settings from './pages/Settings';
// import Test from './components/Test';


function App() {
  const [locale, setLocale] = useState("en");
  const [theme, colorMode] = useMode();


  i18next.init({
    interpolation: { escapeValue: false },
    lng: 'en',
    resources: {
      en: { global: global_en },
      ar: { global: global_ar }
    }
  });

  // direction right and left
  const rtlCache = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  });
  const ltrCache = createCache({
    key: 'mui',
  });

  return (
    <CacheProvider value={locale === "ar" ? rtlCache : ltrCache}>
      <I18nextProvider i18n={i18next}>
        <LocalContext.Provider value={{ locale, setLocale }}>
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              <ToastProvider>
                <CssBaseline />
                <DataProvider>
                  <ProductEditProvider>
                    <Routes>
                      <Route path="/login" element={<Login />} />
                      <Route path='/*' element={<Page404 />} />

                      <Route element={<RequireAuth allowedRoles={[1]} />}>
                        <Route path="/" element={<Dashboard />} >
                          {/* <Route path='test' element={<Test />} /> */}
                          <Route element={<RequireAuth allowedRoles={[1]} />}>
                            <Route path="products" element={<Products />} />
                            <Route path="products/new" element={<ProductsAdd />} />
                            <Route path="products/:id/edit" element={<ProductsEdit />} />
                            <Route path="orders" element={<Orders />} />
                            <Route path="orders/new" element={<OrdersAdd />} />
                            <Route path="orders/:id/edit" element={<OrdersEdit />} />
                            <Route path="orders/:id/details" element={<OrdersView />} />
                            <Route path="users" element={<Users />} />
                            <Route path="users/:id/edit" element={<UsersEdit />} />
                            <Route path="users/new" element={<UsersAdd />} />
                            <Route path="settings" element={<Settings />} />
                            <Route path="categories" element={<Categories />} />
                            <Route path="categories/new" element={<CategoryAdd />} />
                            <Route path="categories/:id/edit" element={<CategoryEdit />} />
                            <Route path="transaction" element={<Transaction />} />
                            <Route path="transaction/:id/edit" element={<TranscationEdit />} />
                            <Route path="transaction/new" element={<TranscationAdd />} />
                          </Route>

                          <Route path='writer' element={<Writer />} />
                        </Route>
                      </Route>

                    </Routes>
                  </ProductEditProvider>
                </DataProvider>
              </ToastProvider>
            </ThemeProvider>
          </ColorModeContext.Provider>
        </LocalContext.Provider>
      </I18nextProvider>
    </CacheProvider>
  );
}

export default App;
