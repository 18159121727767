import React, { useState, useContext, useEffect, useMemo, useRef } from 'react'
import { LocalContext } from '../context/LocalContext';
import ToastContext from '../context/ToastProvider';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { Axios } from '../api/Axios';
import { ORDERS } from '../api/Api';
import { red, green, orange, blue } from '@mui/material/colors';

import '../css/ordersview.css';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import { Icon } from '@iconify/react';

export default function OrdersView() {
    const [order, setOrder] = useState([]);
    const { locale, setLocale } = useContext(LocalContext);
    const { showHideToast } = useContext(ToastContext);
    const navigate = useNavigate();
    const [t] = useTranslation();
    const theme = useTheme();

    const { id } = useParams()

    const handlePrint = () => {
        window.print();
    };

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        handleOpen();
        try {
            Axios.get(`${ORDERS}/${id}`)
                .then(function (response) {
                    console.log(response.data.order)
                    setOrder(response.data.order)
                    handleClose();
                })
                .catch(function (error) {
                    console.log(error)
                    handleClose();
                })
        } catch (error) {
            console.log(error)
            handleClose();
        }
    }, [])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'short', day: '2-digit', hour: 'numeric', minute: '2-digit' };
        return date.toLocaleDateString('en-US', options);
    };

    const calculateSubtotal = () => {
        let subtotal = 0;
        if (order.products) {
            order.products.forEach((product) => {
                const price = product.sale_price ? parseFloat(product.sale_price) : parseFloat(product.price);
                subtotal += price * product.pivot.quantity;
            });
        }
        return subtotal.toFixed(2);
    };
    const calculateTotal = () => {
        const subtotal = calculateSubtotal();
        const shippingCost = parseFloat(order.shipping_cost);
        const discount = parseFloat(order.discount);
        return (subtotal - shippingCost - discount).toFixed(2);
    };

    return (
        <div dir={locale === "en" ? "ltr" : "rtl"} className={[locale === "en" ? "ltr" : "rtl", "ordersview"].join(" ")}>
            {open && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <Container maxWidth="lg">
                <div id="print-area">
                    <div className='header'>
                        <div className='title'>
                            <IconButton className='back' aria-label="back" onClick={() => navigate(-1)}>
                                <NavigateBeforeIcon sx={{ fontSize: '24px' }} />
                            </IconButton>
                            <div className='title-text'>
                                <div className='title-text-name'>
                                    <h4 style={{ fontSize: '1.5rem', margin: '0', fontWeight: 'bold' }}>Order #{order.order_number}</h4>
                                    <span className='title-text-status'
                                        style={{
                                            backgroundColor: order.status === 'complete' ? green[100] : order.status === 'pending' ? orange[100] : order.status === 'failed' ? red[100] : blue[100],
                                            borderColor: order.status === 'complete' ? green[300] : order.status === 'pending' ? orange[300] : order.status === 'failed' ? red[300] : blue[300],
                                            color: order.status === 'complete' ? green[700] : order.status === 'pending' ? orange[700] : order.status === 'failed' ? red[700] : blue[700],
                                        }}>
                                        {order.status}
                                    </span>
                                </div>
                                <p className='title-text-date'>{formatDate(order.created_at)}</p>
                            </div>
                            <img style={{ width: '40px', height: '40px' }} className='logo' src={require('../assets/avatar_25.jpg')} alt="" />
                        </div>
                        <div className='actions'>
                            <button className='print' onClick={handlePrint}>
                                <Icon icon="solar:printer-minimalistic-bold" width="20" height="20" />
                                <div>Print</div>
                            </button>
                            <button className='edit' onClick={(id) => navigate(`/orders/${id}/edit`)}>
                                <Icon icon="solar:pen-bold" width="20" height="20" />
                                <div>Edit</div>
                            </button>
                        </div>
                    </div>
                    <Grid container spacing={2}>
                        <Grid xs={12} md={8}>
                            <div className='price'>
                                <div className='price-head'>
                                    <div className='price-head-details'>Details</div>
                                </div>
                                <div className='price-body'>
                                    <div className='price-body-product'>
                                        {order.products && order.products.map((product) => (
                                            <div key={product.id} className='price-body-product-header'>
                                                <div className='price-body-product-header-image'>
                                                    <img src={product.images.split(',')[0]} alt="" />
                                                </div>
                                                <div className='price-body-product-header-name'>
                                                    <span className='price-body-product-header-name-text'>{locale === "en" ? product.Name_en : product.Name_ar}</span>
                                                </div>
                                                <div className='price-body-product-header-quantity'>x{product.pivot.quantity}</div>
                                                <div className='price-body-product-header-price'>${product.sale_price ? product.sale_price : product.price}</div>
                                            </div>
                                        ))}

                                        <div className='price-body-product-details'>
                                            <div className='price-body-product-details-row'>
                                                <div className='price-body-product-details-row-name'>Subtotal</div>
                                                <div className='price-body-product-details-row-price'>${calculateSubtotal()}</div>
                                            </div>
                                            <div className='price-body-product-details-row'>
                                                <div className='price-body-product-details-row-name'>Shipping</div>
                                                <div className='price-body-product-details-row-shipping'>- ${order.shipping_cost}</div>
                                            </div>
                                            <div className='price-body-product-details-row'>
                                                <div className='price-body-product-details-row-name'>Discount</div>
                                                <div className='price-body-product-details-row-Discount'>- ${order.discount}</div>
                                            </div>
                                            <div className='price-body-product-details-row-total'>
                                                <div className='price-body-product-details-row-name'>Total</div>
                                                <div className='price-body-product-details-row-price'>${calculateTotal()}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <div className='summary'>
                                <div>
                                    <div className='customer-info'>
                                        <div className='customer-info-text'>Customer Info</div>
                                    </div>
                                    <div className='customer-info-details'>
                                        <div className='row'>
                                            <span className='row-label'>Client Name:</span>
                                            <span>{order.full_name}</span>
                                        </div>
                                        <div className='row'>
                                            <span className='row-label'>Email:</span>
                                            <span>{order.email}</span>
                                        </div>
                                        <div className='row'>
                                            <span className='row-label'>Phone:</span>
                                            <span>{order.phone}</span>
                                        </div>
                                    </div>
                                    <hr className='summary-hr' />
                                </div>
                                <div>
                                    <div className='customer-info asd'>
                                        <div className='customer-info-text'>Shipping</div>
                                    </div>
                                    <div className='customer-info-details'>
                                        <div className='row'>
                                            <span className='row-label'>Block:</span>
                                            <span>{order.block}</span>
                                        </div>
                                        <div className='row'>
                                            <span className='row-label'>Street:</span>
                                            <span>{order.street}</span>
                                        </div>
                                        <div className='row'>
                                            <span className='row-label'>House:</span>
                                            <span>{order.house}</span>
                                        </div>
                                        <div className='row'>
                                            <span className='row-label'>Avenue:</span>
                                            <span>{order.avenue}</span>
                                        </div>
                                        <div className='row'>
                                            <span className='row-label'>delivery:</span>
                                            <span>{order.delivery_type}</span>
                                        </div>
                                        <div className='row'>
                                            <span className='row-label'>selectedCity:</span>
                                            <span>{order.state}</span>
                                        </div>
                                        <div className='row'>
                                            <span className='row-label'>Country:</span>
                                            <span>{order.country}</span>
                                        </div>
                                        <div className='row'>
                                            <span className='row-label'>Floor Apartment:</span>
                                            <span>{order.floor_apartment}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>

    )
}
