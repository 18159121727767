import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookie from 'cookie-universal';
import { LOGOUT, baseUrl } from '../api/Api';
import '../css/logout.css';

export default function Logout() {
    const cookies = Cookie()
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    async function handleLogout() {
        try {
            const response = await axios.post(`${baseUrl}${LOGOUT}`, null, {
                headers: {
                    Authorization: `Bearer ${cookies.get('e-commerce')}`
                }
            });
            // console.log(response.data);
            cookies.remove('e-commerce');
            navigate('/login', { replace: true });
        } catch (error) {
            console.log(error);
        }
    }
    

  return (
    <div>
        <Avatar className='avatar-logout' onClick={handleClick} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" aria-controls={open ? 'basic-menu' : undefined} id="basic-button" alt="Remy Sharp" src={require('../assets/avatar_25.jpg')} sx={{ width: 32, height: 32, bgcolor: "#4945ff" }} />
    <Menu
        sx={{width: '200px'}}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
            'aria-labelledby': 'basic-button',
        }}
    >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem> */}
        <MenuItem className='logout-menu' sx={{color: 'rgb(255, 86, 48)', width: '200px',}} onClick={handleLogout}>Logout</MenuItem>
    </Menu>
</div>
  )
}
