import { createContext } from 'react'
import { useState } from 'react'

const data = [
    {
      id: 1,
      Image: require('../assets/dummy1.png'),
      name: {
        NameEN: 'Ahmed',
        NameAR: 'أحمد',
      },
      DescriptionEn: 'Lorem ipsum dolor sit ',
      DescriptionAr: 'أبجد هوز حطى كلمن ',
      Visibility: 'draft',
      Category: 'Electronics',
      Price: 10,
      SalePrice: 8,
      Quantity: 25,
    },
    {
      id: 2,
      Image: require('../assets/dummy2.png'),
      name: {
        NameEN: 'Ahmed',
        NameAR: 'أحمد',
      },
      DescriptionEn: 'Lorem ipsum dolor sit amet.',
      DescriptionAr: 'أبجد هوز حطى كلمن ',
      Visibility: 'published',
      Category: 'Mobile',
      Price: 12,
      SalePrice: 10,
      Quantity: 7,
    },
    {
      id: 3,
      Image: require('../assets/dummy3.png'),
      name: {
        NameEN: 'Ahmed',
        NameAR: 'أحمد',
      },
      DescriptionEn: 'Lorem ipsum dolor sit amet',
      DescriptionAr: 'أبجد هوز حطى كلمن ',
      Visibility: 'draft',
      Category: 'Clothes',
      Price: 8,
      SalePrice: 6,
      Quantity: 15,
    },
  ];

  const catdata = [
    {
      id: 1,
      Image: require('../assets/dummy1.png'),
      NameEN: 'Ahmed',
      NameAR: 'أحمد',
      Visibility: 'draft',
  
    },
    {
      id: 2,
      Image: require('../assets/dummy2.png'),
      NameEN: 'Ahmed',
      NameAR: 'أحمد',
      Visibility: 'published',
  
    },
    {
      id: 3,
      Image: require('../assets/dummy3.png'),
      NameEN: 'Ahmed',
      NameAR: 'أحمد',
      Visibility: 'draft',
    },
  ];

  const orderdata  = [
    {
      id: 1,
      InvoiceNumber: 121235,
      products: [
        { ProductName: 'car', Quantity: 8, UnitPrice: 25, TotalPrice: 200 },
        { ProductName: 'bus', Quantity: 5, UnitPrice: 12, TotalPrice: 90 },
      ],
      Status: 'pending',
    },
    {
      id: 2,
      InvoiceNumber: 121235,
      products: [{ ProductName: 'flower', Quantity: 8, UnitPrice: 25, TotalPrice: 200 }],
      Status: 'completed',
    },
    {
      id: 3,
      InvoiceNumber: 121235,
      products: [{ ProductName: 'toy', Quantity: 8, UnitPrice: 25, TotalPrice: 200 }],
      Status: 'failed',
    },
    {
      id: 4, 
      InvoiceNumber: 121235,
      products: [{ ProductName: 'milk', Quantity: 8, UnitPrice: 25, TotalPrice: 200 }],
      Status: 'processing',
    },
  ];

const DataContext = createContext('')

export const DataProvider = ({ children }) => {
    const [product, setProduct] = useState(data);
    const [category, setCategory] = useState(catdata);
    const [order, setOrder] = useState(orderdata);

    return (
        <DataContext.Provider value={{ product, setProduct, category, setCategory, order, setOrder }}>
            {children}
        </DataContext.Provider>
    )
}
export default DataContext