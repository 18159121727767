import { createContext } from 'react'
import { useState } from 'react'
const ProductEditContext = createContext('')

export const ProductEditProvider = ({ children }) => {
  const [rowToEdit, setRowToEdit] = useState(null);
  const [updatedRows, setUpdatedRows] = useState({
    NameEN: '',
    NameAR: '',
    Status: '',
    Category: '',
    Visibility: false,
    Price: '',
    SalePrice: '',
    Quantity: '',
    DescriptionEn: '',
    DescriptionAr: '',
    Image: null,
    ImagePreview: null
  });
  const [updatedUsers, setUpdatedUsers] = useState({
    Name: '',
    Email: '',
    Password: '',
    Admin: false
  })

  const [updatedOrders, setUpdatedOrders] = useState({
    Status: '',
    Avenue: '',
    Block: '',
    DeliveryType: '',
    FloorApartment: '',
    House: '',
    State: '',
    Street: '',
    TotalOrderPrice: '',
    Notes: '',
    ShippingCost: '',
    Discount: '',
    FullName: '',
    Phone: '',
    Email: '',
    Country: '',
    products: [{ ProductName: '', Quantity: '', UnitPrice: '', TotalPrice: '' }],
  })
  const [selectedCountry, setSelectedCountry] = useState('');

  const [selectedImages, setSelectedImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  return (
    <ProductEditContext.Provider value={{selectedCountry, setSelectedCountry, updatedOrders, setUpdatedOrders, updatedUsers, setUpdatedUsers, selectedImages, setSelectedImages, imagePreviews, setImagePreviews, updatedRows, setUpdatedRows, rowToEdit, setRowToEdit}}>
      {children}
    </ProductEditContext.Provider>
  )
}

export default ProductEditContext