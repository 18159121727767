import React, { useState, useContext, useEffect, useMemo, useRef } from 'react'

import ProductEditContext from '../context/ProductEditProvider';
import { LocalContext } from '../context/LocalContext';
import ToastContext from '../context/ToastProvider';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Axios } from '../api/Axios';
import { CATEGORIES } from '../api/Api';
import Cookie from 'cookie-universal';

import '../css/categoryedit.css';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';

export default function ProductsEdit() {
    const [category, setCategory] = useState([]);
    const { updatedRows, setUpdatedRows, rowToEdit } = useContext(ProductEditContext);
    const { locale, setLocale } = useContext(LocalContext);
    const { showHideToast } = useContext(ToastContext);
    const navigate = useNavigate();
    const [t] = useTranslation();
    const theme = useTheme();

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const handleImageChange = (event) => {
        const imageFile = event.target.files[0];
        const imagePreviewURL = URL.createObjectURL(imageFile);
        setUpdatedRows({
            ...updatedRows,
            Image: imageFile,
            ImagePreview: imagePreviewURL
        });
    };

    const removeImage = () => {
        setUpdatedRows({
            ...updatedRows,
            Image: null,
            ImagePreview: null
        });
    }

    async function handleEditSubmit(e) {
        e.preventDefault();
        handleOpen();
        let form = new FormData();
        form.append('_method', 'PUT');
        form.append("Name_en", updatedRows.NameEN);
        form.append("Name_ar", updatedRows.NameAR);
        form.append("visibility", updatedRows.Visibility ? "1" : "0");
        if (updatedRows.Image !== null) {
            form.append('image', updatedRows.Image);
        }

        try {
            const response = await Axios.post(`${CATEGORIES}/${rowToEdit}`, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            // console.log(response.data);
            const updatedCategory = response.data.category;
            const updatedRowsArray = category.map((row) => {
                if (row.id === rowToEdit) {
                    return {
                        ...row,
                        NameEN: updatedCategory.Name_en,
                        NameAR: updatedCategory.Name_ar,
                        Visibility: updatedCategory.visibility,
                        Image: updatedCategory.image
                    };
                }
                return row;
            })
            setCategory(updatedRowsArray);
            showHideToast(t("Updated successfully"));
            handleClose();
            navigate("/categories");
        } catch (error) {
            console.log(error);
            handleClose();
        }
    }

    //  ================ edit function ================

    return (
        <div dir={locale === "en" ? "ltr" : "rtl"} className={[locale === "en" ? "ltr" : "rtl", "categoryEdit"].join(" ")}>
            {open && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <Container maxWidth="lg">
                <h4 style={{ fontSize: '24px', fontWeight: 'bold', margin: '0 0 40px 0' }}>
                    {t('Edit')}
                </h4>
                <Grid container spacing={2}>
                    <Grid md={4}>
                        <h6 style={{ fontSize: '18px', marginBottom: '4px' }}>Details</h6>
                        <p style={{ fontSize: '14px', margin: '0', color: '#637381' }}>Title, short description, image...</p>
                    </Grid>
                    <Grid xs={12} md={8}>
                        <div className="firstbox">
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', padding: '24px' }}>
                                <TextField value={updatedRows.NameEN} onChange={(e) => { setUpdatedRows({ ...updatedRows, NameEN: e.target.value }) }} fullWidth id="Name-EN" label={t("Category Name EN")} variant="outlined" />
                                <TextField value={updatedRows.NameAR} onChange={(e) => { setUpdatedRows({ ...updatedRows, NameAR: e.target.value }) }} fullWidth id="Name-AR" label={t("Category Name AR")} variant="outlined" />

                                <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                    <h6 style={{ fontSize: '14px', margin: '0' }}>Images</h6>
                                    <div style={{ width: '100%', position: 'relative' }}>
                                        <div className='images' onClick={() => document.querySelector('.input-field').click()}>
                                            <input onChange={handleImageChange} className='input-field' style={{ display: 'none' }} accept="image/*" type="file" />
                                            <div className='image'>
                                                <CloudUploadIcon sx={{ fontSize: '150px', color: '#212b36' }} />
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', textAlign: 'center' }}>
                                                    <h6 style={{ fontSize: '1.125rem', margin: '0', fontWeight: 700 }}>Drop or Select file</h6>
                                                    <p className='para'>
                                                        Drop files here or click
                                                        <span className='browse'>browse</span>
                                                        thorough your machine
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ margin: '24px 0 24px 0' }}>
                                            {updatedRows.ImagePreview && (
                                                <div className='preview'>
                                                    <span className='prev-image'>
                                                        <img src={updatedRows.ImagePreview} alt="" />
                                                        <button className='close' onClick={() => removeImage()}>
                                                            <CloseIcon sx={{ fontSize: '14px', color: 'white' }} />
                                                        </button>
                                                    </span>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>

                    <Grid md={4}>
                        <h6 style={{ fontSize: '18px', marginBottom: '4px' }}>Properties</h6>
                        <p style={{ fontSize: '14px', margin: '0', color: '#637381' }}>Additional functions and attributes..</p>
                    </Grid>
                    <Grid xs={12} md={8}>
                        <div className="firstbox">
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', padding: '24px' }}>
                                <Grid container spacing={2}>
                                    <Grid xs={12} md={12}>
                                        <span>{t('Visibility')}</span>
                                        <Switch
                                            checked={Boolean(updatedRows.Visibility)}
                                            onChange={(e) => {
                                                setUpdatedRows({ ...updatedRows, Visibility: e.target.checked })
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid md={4}></Grid>
                    <Grid xs={12} md={8} sx={{ padding: '16px', textAlign: 'right' }}>
                        <button onClick={handleEditSubmit} className='submitbtn' variant="contained" type="submit">{t('Save Changes')}</button>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
