import React, { useState, useContext, useEffect, useMemo, useRef } from 'react'
import { LocalContext } from '../context/LocalContext';
import ToastContext from '../context/ToastProvider';
import ProductEditContext from '../context/ProductEditProvider';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ReactFlagsSelect from "react-flags-select";
import axios from 'axios';
import { Axios } from '../api/Axios';
import { ORDERS, baseUrl, PRODUCTS } from '../api/Api';


import '../css/ordersedit.css';

import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Unstable_Grid2';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';



export default function OrdersAdd() {
  const [order, setOrder] = useState([]);
  const [apiproduct, setApiProduct] = useState([]);
  const { updatedOrders, setUpdatedOrders, rowToEdit, selectedCountry, setSelectedCountry } = useContext(ProductEditContext);
  const { locale, setLocale } = useContext(LocalContext);
  const { showHideToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const [t] = useTranslation();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  //  ====== get all products ========
  useEffect(() => {
    handleOpen();
    axios.get(`${baseUrl}${PRODUCTS}`,)
      .then(function (response) {
        console.log('products ', response.data.products);
        setApiProduct(response.data.products);
        handleClose();
      })
      .catch(function (error) {
        console.log(error);
        showHideToast(error.response.data.message, 'error');
        handleClose();
      }
      );
  }, []);
  //  ====== get all products ========

  //  ================ edit state ================
  function handleform(e) {
    setUpdatedOrders({ ...updatedOrders, [e.target.name]: e.target.value })
  }
  //  ================ edit state ================

  //  ================ edit function ================

  const handleProductNameChange = (event, index) => {
    const selectedProductId = event.target.value;
    const selectedProduct = apiproduct.find(product => product.id === selectedProductId);

    const updatedProducts = [...updatedOrders.products];
    updatedProducts[index].ProductName = selectedProductId;
    updatedProducts[index].UnitPrice = (selectedProduct ? (selectedProduct.sale_price ? selectedProduct.sale_price : selectedProduct.price) : '');
    updatedProducts[index].TotalPrice = ''; // Reset total price when product changes
    updatedProducts[index].Quantity = '';

    setUpdatedOrders({ ...updatedOrders, products: updatedProducts });
  };

  const handleQuantityChange = (event, index) => {
    const updatedProducts = [...updatedOrders.products];
    const quantity = parseFloat(event.target.value);
    const unitPrice = parseFloat(updatedProducts[index].UnitPrice);

    if (!isNaN(quantity) && !isNaN(unitPrice)) {
      updatedProducts[index].Quantity = quantity;
      updatedProducts[index].TotalPrice = (quantity * unitPrice).toFixed(2);
    } else {
      updatedProducts[index].Quantity = '';
      updatedProducts[index].TotalPrice = '';
    }

    setUpdatedOrders({ ...updatedOrders, products: updatedProducts });
  };


  // Function to add a new product row
  const handleAddProduct = () => {
    setUpdatedOrders({
      ...updatedOrders,
      products: [...updatedOrders.products, { ProductName: '', Quantity: '', UnitPrice: '', TotalPrice: '' }],
    });
  };

  const handleRemoveProduct = (index) => {
    const updatedProducts = [...updatedOrders.products];
    updatedProducts.splice(index, 1);
    setUpdatedOrders({ ...updatedOrders, products: updatedProducts });
  };

  async function handleDialogSubmit(e) {
    e.preventDefault();
    handleOpen();
    const ProductsData = updatedOrders.products.map(product => ({
      id: product.ProductName,
      quantity: product.Quantity,
    }));
    const params = {
      status: updatedOrders.Status,
      avenue: updatedOrders.Avenue,
      block: updatedOrders.Block,
      delivery_type: updatedOrders.DeliveryType,
      floor_apartment: updatedOrders.FloorApartment,
      house: updatedOrders.House,
      state: updatedOrders.State,
      street: updatedOrders.Street,
      total_order_price: updatedOrders.TotalOrderPrice,
      products: ProductsData,
      country: updatedOrders.Country,
      additional_notes: updatedOrders.Notes,
      shipping_cost: updatedOrders.ShippingCost,
      discount: updatedOrders.Discount,
      full_name: updatedOrders.FullName,
      phone: updatedOrders.Phone,
      email: updatedOrders.Email
    }
    console.log(params);
    try {
      const response = await Axios.put(`${ORDERS}/${rowToEdit}`, params)
      console.log(response);
      const updatedOrder = response.data.order;
      const updatedRowsArray = order.map((row) => {
        if (row.id === rowToEdit) {
          return {
            ...row,
            Status: updatedOrder.status,
            Avenue: updatedOrder.avenue,
            Block: updatedOrder.block,
            DeliveryType: updatedOrder.delivery_type,
            FloorApartment: updatedOrder.floor_apartment,
            House: updatedOrder.house,
            State: updatedOrder.state,
            Street: updatedOrder.street,
            TotalOrderPrice: updatedOrder.total_order_price,
            products: updatedOrder.products,
            Country: updatedOrder.country,
            AdditionalNotes: updatedOrder.additional_notes,
            ShippingCost: updatedOrder.shipping_cost,
            Discount: updatedOrder.discount,
            FullName: updatedOrder.full_name,
            Phone: updatedOrder.phone,
            Email: updatedOrder.email
          };
        }
        return row;
      })
      setOrder((prevRows) => [...prevRows, updatedRowsArray]);
      showHideToast(t("Updated successfully"));
      handleClose();
      navigate("/orders");
    } catch (error) {
      console.log(error);
      handleClose();
    }
  }
  //  ================ add function ================

  return (
    <div dir={locale === "en" ? "ltr" : "rtl"} className={[locale === "en" ? "ltr" : "rtl", "ordersEdit"].join(" ")}>
      {open && <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>}
      <Container maxWidth="xl">
        <h4 style={{ fontSize: '24px', fontWeight: 'bold', margin: '0' }}>
          {t('Edit')}
        </h4>
        <Grid container spacing={2}>
          <Grid md={4}>
            <h6 style={{ fontSize: '18px', marginBottom: '4px' }}>Details</h6>
            <p style={{ fontSize: '14px', margin: '0', color: '#637381' }}>ProductName, Quantity, Price...</p>
          </Grid>
          <Grid xs={12} md={12}>
            <div className="firstbox">
              {updatedOrders.products.map((product, index) => (
                <div key={index} style={{ display: 'flex', flexDirection: 'column', gap: '24px', padding: '24px' }}>
                  <Grid container spacing={2}>
                    <Grid xs={12} md={6} lg={3}>
                      <FormControl fullWidth>
                        <InputLabel>{t('Product Name')}</InputLabel>
                        <Select
                          value={product.ProductName}
                          onChange={(e) => handleProductNameChange(e, index)}
                          name="Product Name"
                          required
                        >
                          {apiproduct.map((item) => (
                            <MenuItem key={item.id} value={item.id}>{locale === "en" ? item.Name_en : item.Name_ar}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid xs={12} md={6} lg={3}>
                      <TextField value={product.Quantity} onChange={(e) => handleQuantityChange(e, index)} fullWidth id="Quantity" label={t("Quantity")} variant="outlined" />
                    </Grid>
                    <Grid xs={12} md={6} lg={2}>
                      <TextField sx={{ cursor: "not-allowed", userSelect: "none" }} disabled value={product.UnitPrice} fullWidth id="UnitPrice" label={t("Unit Price")} variant="outlined" />
                    </Grid>
                    <Grid xs={12} md={6} lg={2}>
                      <TextField sx={{ cursor: "not-allowed", userSelect: "none" }} disabled value={product.TotalPrice} fullWidth id="TotalPrice" label={t("Total Price")} variant="outlined" />
                    </Grid>
                    <Grid xs={12} md={6} lg={2}>
                      <IconButton color="error" onClick={() => handleRemoveProduct(index)} aria-label="remove-room">
                        <ClearIcon />
                      </IconButton>
                      <IconButton color="primary" aria-label="add product" onClick={handleAddProduct}>
                        <AddIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </div>
              ))}
            </div>
          </Grid>

          <Grid md={4}>
            <h6 style={{ fontSize: '18px', marginBottom: '4px' }}>Properties</h6>
            <p style={{ fontSize: '14px', margin: '0', color: '#637381' }}>Additional functions and attributes..</p>
          </Grid>
          <Grid xs={12} md={12}>
            <div className="firstbox">
              <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', padding: '24px' }}>
                <Grid container spacing={2}>
                  <Grid xs={12} md={6} lg={3}>
                    <FormControl fullWidth>
                      <InputLabel>{t('Status')}</InputLabel>
                      <Select
                        value={updatedOrders.Status}
                        onChange={handleform}
                        name="Status"
                        required
                      >
                        <MenuItem value="complete">completed</MenuItem>
                        <MenuItem value="pending">pending</MenuItem>
                        <MenuItem value="failed">failed</MenuItem>
                        <MenuItem value="processing">processing</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} md={6} lg={3}>
                    <TextField name='TotalOrderPrice' onChange={handleform} value={updatedOrders.TotalOrderPrice} fullWidth id="total_order_price" label="Total Order Price" variant="outlined" />
                  </Grid>
                  <Grid xs={12} md={6} lg={3}>
                    <TextField name='ShippingCost' onChange={handleform} value={updatedOrders.ShippingCost} fullWidth id="shipping_cost" label="Shipping Cost" variant="outlined" />
                  </Grid>
                  <Grid xs={12} md={6} lg={3}>
                    <TextField name='Discount' onChange={handleform} value={updatedOrders.Discount} fullWidth id="discount" label="Discount" variant="outlined" />
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>

          <Grid md={4}>
            <h6 style={{ fontSize: '18px', marginBottom: '4px' }}>Client Info</h6>
            <p style={{ fontSize: '14px', margin: '0', color: '#637381' }}>Name, Email, Phone number...</p>
          </Grid>
          <Grid xs={12} md={12}>
            <div className="firstbox">
              <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', padding: '24px' }}>
                <Grid container spacing={2}>
                  <Grid xs={12} md={6} lg={3}>
                    <TextField name='FullName' onChange={handleform} value={updatedOrders.FullName} fullWidth id="full_name" label="Full Name" variant="outlined" />
                  </Grid>
                  <Grid xs={12} md={6} lg={3}>
                    <TextField fullWidth name='Phone' onChange={handleform} value={updatedOrders.Phone} id="phone" label="Phone" variant="outlined" />
                  </Grid>
                  <Grid xs={12} md={6} lg={3}>
                    <TextField name='Email' onChange={handleform} value={updatedOrders.Email} fullWidth id="email" label="Email" variant="outlined" />
                  </Grid>
                  <Grid xs={12} md={6} lg={3}>
                    <TextField name='Notes' onChange={handleform} value={updatedOrders.Notes} fullWidth id="additional_notes" label="Additional Notes" variant="outlined" />
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>

          <Grid md={4}>
            <h6 style={{ fontSize: '18px', marginBottom: '4px' }}>Shipping</h6>
            <p style={{ fontSize: '14px', margin: '0', color: '#637381' }}>Address, Country...</p>
          </Grid>
          <Grid xs={12} md={12}>
            <div className="firstbox">
              <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', padding: '24px' }}>
                <Grid container spacing={2}>
                  <Grid xs={12} md={6} lg={3}>
                    <TextField name='Avenue' onChange={handleform} value={updatedOrders.Avenue} fullWidth id="avenue" label="avenue" variant="outlined" />
                  </Grid>
                  <Grid xs={12} md={6} lg={3}>
                    <TextField fullWidth name='Block' onChange={handleform} value={updatedOrders.Block} id="block" label="block" variant="outlined" />
                  </Grid>
                  <Grid xs={12} md={6} lg={3}>
                    <ReactFlagsSelect
                      selected={updatedOrders.Country}
                      onSelect={(e) => setUpdatedOrders({ ...updatedOrders, Country: e })}
                      placeholder="Select Country"
                      searchable
                      searchPlaceholder="Search countries"
                      className="menu-flags"
                      selectButtonClassName="menu-flags-button"
                      name="Country"
                    />
                  </Grid>
                  <Grid xs={12} md={6} lg={3}>
                    <TextField name='FloorApartment' onChange={handleform} value={updatedOrders.FloorApartment} fullWidth id="floor_apartment" label="floor apartment" variant="outlined" />
                  </Grid>
                  <Grid xs={12} md={6} lg={3}>
                    <TextField name='House' onChange={handleform} value={updatedOrders.House} fullWidth id="house" label="house" variant="outlined" />
                  </Grid>
                  <Grid xs={12} md={6} lg={3}>
                    <TextField name='State' onChange={handleform} value={updatedOrders.State} fullWidth id="state" label="state" variant="outlined" />
                  </Grid>
                  <Grid xs={12} md={6} lg={3}>
                    <TextField name='Street' onChange={handleform} value={updatedOrders.Street} fullWidth id="street" label="street" variant="outlined" />
                  </Grid>
                  <Grid xs={12} md={6} lg={3}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Delivery Type</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="DeliveryType"
                        value={updatedOrders.DeliveryType}
                        label="Age"
                        onChange={handleform}
                      >
                        <MenuItem value="House">House</MenuItem>
                        <MenuItem value="Work">Work</MenuItem>
                        <MenuItem value="Apartment">Apartment</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>

        </Grid>
        <Grid container spacing={2}>
          <Grid md={4}></Grid>
          <Grid xs={12} md={8} sx={{ padding: '16px', textAlign: 'right' }}>
            <button onClick={handleDialogSubmit} className='submitbtn' variant="contained" type="submit">{t('Create Order')}</button>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
