import { Container } from '@mui/material';
import { Typography, Stack, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SettingsContent from '../components/SettingsContent';

export default function Settings() {
    const [t, i18n] = useTranslation("global");
    return (
        <div>
            <Container maxWidth="xl">
                <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 2, mt: '20px' }}>
                    <Typography sx={{ fontSize: '20px', fontWeight: 'bold', mb: '20px' }}>
                        {t('Settings')}
                    </Typography>
                </Stack>
                <SettingsContent />
            </Container>
        </div>
    )
}
