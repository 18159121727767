import React, { useState, useContext, useEffect, useMemo, useRef } from 'react'
import { LocalContext } from '../context/LocalContext';
import ToastContext from '../context/ToastProvider';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { Axios } from '../api/Axios';
import { ORDERS, TRANSCATION } from '../api/Api';

import '../css/usersadd.css';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import { Stack } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';


export default function TranscationEdit() {
  const [transcation, setTranscation] = useState([]);
  const [orders, setOrders] = useState([]);
  const { locale, setLocale } = useContext(LocalContext);
  const { showHideToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const [t] = useTranslation();
  const theme = useTheme();

  const { id } = useParams()
  const [read, setRead] = useState({
    OrderId: '',
    TransactionId: '',
    PaymentMethod: '',
    Amount: '',
    Status: '',
  });
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  //  ====== get all data ========
  useEffect(() => {
    handleOpen();
    Axios.get(`${TRANSCATION}/${id}`)
      .then(function (response) {
        console.log(response.data.transaction);
        setRead({
          OrderId: response.data.transaction.order_id,
          TransactionId: response.data.transaction.transaction_id,
          PaymentMethod: response.data.transaction.payment_method,
          Amount: response.data.transaction.amount,
          Status: response.data.transaction.status
        });
        handleClose();
      })
      .catch(function (error) {
        console.log(error);
        showHideToast(error.response.data.message, 'error');
        handleClose();
      })
  }, [])

  useEffect(() => {
    handleOpen();
    Axios.get(`${ORDERS}`,)
      .then(function (response) {
        console.log(response.data.orders);
        setOrders(response.data.orders);
        handleClose();
      })
      .catch(function (error) {
        console.log(error);
        showHideToast(error.response.data.message, 'error');
        handleClose();
      });
  }, []);
  //  ====== get all data ========

  //  ================ edit state ================
  function handleForm(e) {
    setRead({ ...read, [e.target.name]: e.target.value })
  }
  //  ================ edit state ================

  //  ================ edit function ================
  async function handleEditSubmit(e) {
    e.preventDefault();
    handleOpen();
    const params = {
      order_id: read.OrderId,
      transaction_id: read.TransactionId,
      payment_method: read.PaymentMethod,
      amount: read.Amount,
      status: read.Status
    }
    try {
      const response = await Axios.put(`${TRANSCATION}/${id}`, params)
      console.log(response);
      showHideToast(t("Updated successfully"));
      handleClose();
      navigate("/transaction");
    } catch (error) {
      console.log(error);
      handleClose();
    }
  }
  //  ================ edit function ================

  return (
    <div dir={locale === "en" ? "ltr" : "rtl"} className={[locale === "en" ? "ltr" : "rtl", "usersadd"].join(" ")}>
      {open && <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>}
      <Container maxWidth="lg">
        <h4 style={{ fontSize: '24px', fontWeight: 'bold', margin: '0 0 40px 0' }}>
          {t('Edit')}
        </h4>

        <Grid container spacing={2}>
          <Grid md={4}>
            <h6 style={{ fontSize: '18px', marginBottom: '4px' }}>Properties</h6>
            <p style={{ fontSize: '14px', margin: '0', color: '#637381' }}>Additional functions and attributes..</p>
          </Grid>
          <Grid xs={12} md={8}>
            <div className="firstbox">
              <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', padding: '24px' }}>
                <TextField type='text' autoFocus name='TransactionId' value={read.TransactionId} onChange={handleForm} fullWidth id="transaction_id" label={t("Transaction Id")} variant="outlined" />
                <TextField type='text' name='PaymentMethod' value={read.PaymentMethod} onChange={handleForm} fullWidth id="payment_method" label={t("Payment Method")} variant="outlined" />
                <TextField type='text' name='Amount' value={read.Amount} onChange={handleForm} fullWidth id="amount" label={t("Amount")} variant="outlined" />
                <FormControl fullWidth>
                  <InputLabel>{t('Order Number')}</InputLabel>
                  <Select
                    value={read.OrderId}
                    onChange={handleForm}
                    name="OrderId"
                    required
                  >
                    {orders.map(order => (
                      <MenuItem key={order.id} value={order.id}>{order.order_number}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel>{t('Status')}</InputLabel>
                  <Select
                    value={read.Status}
                    onChange={handleForm}
                    name="Status"
                    required
                  >
                    <MenuItem value="success">success</MenuItem>
                    <MenuItem value="pending">pending</MenuItem>
                    <MenuItem value="failed">failed</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid md={4}></Grid>
          <Grid xs={12} md={8} sx={{ padding: '16px', textAlign: 'right' }}>
          <button onClick={handleEditSubmit} className='submitbtn' variant="contained" type="submit">{t('Save Changes')}</button>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
